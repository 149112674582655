import Vue from 'vue';
import Vuex from 'vuex';
import ApiService from '../services/ApiService';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loggedInUser: {},
    jumpTarget: null,
    levelChanged: false,
    levelManuallySet: false,
    userReadOnly: false,
  },
  getters: {
    loggedInUser: (state) => state.loggedInUser,
    jumpTarget: (state) => state.jumpTarget,
    levelChanged: (state) => state.levelChanged,
    levelManuallySet: (state) => state.levelManuallySet,
    userReadOnly: (state) => state.userReadOnly,
    // eslint-disable-next-line consistent-return
    initials: (state) => {
      if (state.loggedInUser.firstName) {
        return state.loggedInUser.firstName[0] + state.loggedInUser.lastName[0];
      }
    },
  },
  mutations: {
    setLoggedInUser(state, user) {
      state.loggedInUser = user;
    },
    setJumpTarget(state, target) {
      state.jumpTarget = target;
    },
    setLevelChanged(state, levelChanged) {
      state.levelChanged = levelChanged;
    },
    setlevelManuallySet(state, levelManuallySet) {
      state.levelManuallySet = levelManuallySet;
    },
    setUserReadOnly(state, userReadOnly) {
      state.userReadOnly = userReadOnly;
    },
  },
  actions: {
    getLoggedInUser({ commit }) {
      ApiService.getLoggedinUser().then((response) => {
        if (response) {
          commit('setLoggedInUser', response.data);
          commit('setUserReadOnly', response.data.readOnly);
          localStorage.setItem('currentUser', JSON.stringify(response.data));
        }
      })
        .catch((error) => console.log(error));
    },
  },
  modules: {
  },
});
