<template>
  <v-footer color="#fff">
    <v-container fluid>
      <v-row v-if="fullFooter">
        <v-col cols="12" class="mb-5">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" md="4" sm="6" class="mt-5">
          <h6>Documentation</h6>
          <div class="legal-links no-divider color-gray mt-4">
            <div class="legal-links__item d-block">
              <a class="legal-links__link" target="_blank"
                 href="https://sie.ag/35eeNP5">Introduction Tutorial [Video]</a>
            </div>
            <div class="legal-links__item d-block">
              <a class="legal-links__link" target="_blank"
                 href="https://sie.ag/3mzF7dx">New Features in Version 2.0 [Video]</a>
            </div>
            <div class="legal-links__item d-block">
              <a class="legal-links__link" target="_blank"
                 href="https://bit.ly/3pLiO9F">New Features in Version 2.1 [PDF]</a>
            </div>
            <div class="legal-links__item d-block">
              <a class="legal-links__link" target="_blank"
                 href="https://bit.ly/3nDOECP">Guardrails for FY22 [PDF]</a>
            </div>
            <div class="legal-links__item d-block">
              <a class="legal-links__link" target="_blank"
                 href="https://bit.ly/2GaowOa">Guardrails SharePoint</a>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="4" sm="6" class="mt-5">
          <h6>Support & Questions</h6>
          <v-btn
            class="mt-4"
            rounded
            color="primary"
            depressed
            @click="sendMailContact"
          >
            Contact Us
          </v-btn>
        </v-col>
        <v-col cols="12" md="4" sm="6" class="mt-5">
          <h6>Feedback</h6>
          <div class="feedback-links mt-4">
            <v-icon size="32" @click="sendMailGood">mdi-emoticon-happy-outline</v-icon>
            <v-icon class="ml-7" size="32" @click="sendMailBad">mdi-emoticon-sad-outline</v-icon>
          </div>
        </v-col>
        <v-col lg="3" class="mt-5"></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="mt-5">
          <v-divider></v-divider>
          <div class="footer__legal-links d-flex align-center mt-5">
            <ul class="legal-links color-gray">
              <li class="legal-links__item color-gray">Siemens Medical Solutions USA, Inc. &copy;
                {{ new Date().getFullYear() }}</li>
              <li class="legal-links__item">
                <a class="legal-links__link" target="_blank"
                   href="https://www.siemens-healthineers.com/en-us/corporate-info">Corporate Information</a>
              </li>
              <li class="legal-links__item">
                <a class="legal-links__link" target="_blank"
                   href="https://www.siemens-healthineers.com/en-us/siemens-website-privacy-policy">Privacy Policy</a>
              </li>
              <li class="legal-links__item">
                <a class="legal-links__link" target="_blank"
                   href="https://www.siemens-healthineers.com/cookie">Cookie Policy</a>
              </li>
              <li class="legal-links__item">
                <a class="legal-links__link" target="_blank"
                   href="https://www.siemens-healthineers.com/en-us/terms-of-use">Terms of Use</a>
              </li>
            </ul>
            <v-spacer></v-spacer>
            <span v-if="version" class="mt-4 mt-sm-0 color-gray text-smaller">Version: {{ version }}</span>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import packageInfo from '../../package.json';

export default {
  name: 'ShsFooter',
  props: {
    fullFooter: Boolean,
  },
  data() {
    const gitHash = process.env.VUE_APP_GIT_HASH;
    const fullVersion = gitHash ? `${packageInfo.version}@${gitHash}` : `${packageInfo.version}`;
    return {
      version: fullVersion,
    };
  },
  methods: {
    sendMailContact() {
      window.location = 'mailto:dxpricing-escalations.team@siemens-healthineers.com';
    },
    sendMailGood() {
      window.location = 'mailto:dxpricing-escalations.team@siemens-healthineers.com?subject=What I like about Pricing Escalation App';
    },
    sendMailBad() {
      window.location = 'mailto:dxpricing-escalations.team@siemens-healthineers.com?subject=I have a proposal for Pricing Escalation App';
    },
  },
};
</script>

<style lang="scss">
  .v-application {
    .legal-links.no-divider .legal-links__item:after {
      content: '';
      margin: 0;
    }
    .footer__legal-links {
      flex-direction: column;
      > span {
        line-height: 2;
      }
    }
    // desktop, tablet
    @media screen and (min-width: 600px) {
      .footer__legal-links {
        flex-direction: row;
      }
    }
    .legal-links {
      list-style-type:none;
      margin:0;
      padding-left:0;
      line-height:2;
      font-size:.8em;
    }
    .legal-links {
      &__item {
        display: inline-block;
        margin: 0;
        a {
          text-decoration: none;
        }
        a:hover, a:active {
          box-shadow: none;
          text-decoration: none;
        }
      }
      &__item:after {
        display: inline-block;
        content: "|";
        margin: 0 .2em;
      }
      &__item:last-of-type:after {
        display: inline-block;
        content: "";
      }
      &__link {
        color: inherit;
        &:hover {
          color: #1a1a1a;
        }
      }
    }
    .feedback-links a {
      text-decoration: none;
    }
  }

</style>
