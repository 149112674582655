import Vue from 'vue';
import VueRouter from 'vue-router';
import ArchiveOverview from '@/views/archive/ArchiveOverview.vue';
import OverlayArchiveDetail from '@/views/archive/OverlayArchiveDetail.vue';
import OverlayUserArchiveDetail from '@/views/userarchive/OverlayUserArchiveDetail.vue';
import Index from '../views/Index.vue';
import OverlayDealDetail from '../views/OverlayDealDetail.vue';
import Overview from '../views/Overview.vue';
import OverlayDealUpload from '../components/DealUpload.vue';
import OverlayUserDetail from '../views/user/OverlayUserDetail.vue';
import AuthLoading from '../views/AuthLoading.vue';
import { isMSALUserLoggedIn, authenticateMSALUser } from '../plugins/auth';

Vue.use(VueRouter);

const hasNoAccessQueryParam = (route) => route.query.message === 'Noaccess';
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Index,
    meta: { requiresAuth: false },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/board',
    name: 'overview',
    component: Overview,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'upload',
        name: 'upload',
        component: OverlayDealUpload,
      },
      {
        path: ':id',
        name: 'details',
        component: OverlayDealDetail,
        beforeEnter: (to, from, next) => {
          const userId = Number.parseInt(to.params.id, 10);
          if (Number.isNaN(userId)) {
            next({ name: 'NotFound' });
          }
          next();
        },
      },
    ],
  },
  {
    path: '/archive',
    name: 'archive',
    component: ArchiveOverview,
    meta: { requiresAuth: true },
    children: [
      {
        path: ':id',
        name: 'archivedetails',
        component: OverlayArchiveDetail,
        beforeEnter: (to, from, next) => {
          const userId = Number.parseInt(to.params.id, 10);
          if (Number.isNaN(userId)) {
            next({ name: 'NotFound' });
          }
          next();
        },
      },
    ],
  },
  {
    path: '/user-archive',
    name: 'userarchive',
    component: () => import('../views/userarchive/UserArchiveOverview.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: ':id',
        name: 'userarchivedetails',
        component: OverlayUserArchiveDetail,
      },
    ],
  },
  {
    path: '/user-management',
    name: 'users',
    component: () => import('../views/user/UserOverview.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'create',
        name: 'usercreate',
        component: OverlayUserDetail,
      },
      {
        path: ':id',
        name: 'userdetails',
        component: OverlayUserDetail,
      },
    ],
  },
  {
    path: '/auth-loading',
    name: 'AuthLoading',
    component: AuthLoading,
    meta: { requiresAuth: false },
  },
  {
    path: '/not-found',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '*',
    redirect: '/not-found',
    meta: { requiresAuth: false },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // if requested route is home page and user is already logged in, redirect to board
  if (to.name === 'Home' && !hasNoAccessQueryParam(to)) {
    isMSALUserLoggedIn().then(() => {
      console.log('User already logged in, redirect to board');
      next({ path: '/board' });
    }).catch(() => {
      next();
    });
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    isMSALUserLoggedIn().then(() => {
      next();
    }).catch(() => {
      console.log('User not logged in, starting authentication');
      authenticateMSALUser(to.path);
    });
  } else {
    next();
  }
});

export default router;
