<template>
  <div class="shs-header"
       :class="{ 'border-bottom': hideExtensionBar && !isExtraSmallScreenSize }"
  >
    <v-app-bar
      light
      color="white"
      elevation="0"
      :height="setHeaderHeight"
      width="100vw"
      extension-height="70"
    >
      <v-toolbar-items class="d-flex align-center">
        <a href="/board" class="mr-10">
          <v-img
            class="hidden-576-and-down"
            src="@/assets/sh_logo_rgb.svg"
            width="216"
            height="52"
          ></v-img>
          <v-img
            class="hidden-576-and-up"
            src="@/assets/sh_logo_rgb.svg"
            width="125"
            height="30"
          ></v-img>
        </a>
        <div class="app-title-headline mr-10 hidden-759-to-577 hidden-576-and-down text-small">
          SHS Pricing Escalation
<!--          <v-chip
            class="ml-3 app-title-label hidden-sm-and-down"
            label
            outlined
            small
            color="#02787d"
            >Beta Version
          </v-chip>-->
        </div>
      </v-toolbar-items>
      <template v-if="fullHeader">
        <v-spacer></v-spacer>
        <template v-if="!isUser && !isArchive && !isUserArchive">
          <v-text-field
            v-model="searchTerm"
            flat
            outlined
            rounded
            solo
            hide-details
            dense
            clearable
            append-icon="mdi-magnify"
            label="Search in all Deals"
            class="search-field hidden-576-and-down mr-4"
            v-on:keyup="emitSearchFilter"
            v-on:focus.once="emitGetDealsComplete"
          ></v-text-field>
        </template>
        <template v-else-if="isArchive">
          <v-text-field
            v-model="searchTerm"
            flat
            outlined
            rounded
            solo
            hide-details
            dense
            clearable
            append-icon="mdi-magnify"
            label="Search in Archive"
            class="search-field hidden-576-and-down mr-4"
            v-on:keyup.enter="emitSearchFilter"
            v-on:click:clear="emitSearchCleared"
          ></v-text-field>
        </template>
        <template v-else-if="isUserArchive">
          <v-text-field
            v-model="searchTerm"
            flat
            outlined
            rounded
            solo
            hide-details
            dense
            clearable
            append-icon="mdi-magnify"
            label="Search in User Archive"
            class="search-field hidden-576-and-down mr-4"
            v-on:keyup="emitSearchFilter"
          ></v-text-field>
        </template>
        <template v-else>
          <v-text-field
            v-model="searchTerm"
            flat
            outlined
            rounded
            solo
            hide-details
            dense
            clearable
            append-icon="mdi-magnify"
            label="Search User"
            class="search-field hidden-576-and-down mr-4"
            v-on:keyup="emitSearchFilter"
          ></v-text-field>
        </template>
        <v-toolbar-items class="hidden-576-and-down">
          <v-list-item>
            <v-menu offset-y v-if="$store.state.loggedInUser.firstName">
              <template v-slot:activator="{ on, attrs }">
                <v-app-bar-nav-icon
                  v-bind="attrs"
                  v-on="on"
                >
                </v-app-bar-nav-icon>
              </template>
              <v-list>
                <v-list-item two-line
                  :to="`/user-management/${$store.state.loggedInUser.id}`"
                >
                  <v-list-item-avatar
                    color="#e6e6e6"
                    size="40"
                  >
                    {{ $store.getters.initials }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="text-smaller font-weight-bold">
                      {{ $store.state.loggedInUser.firstName }}
                      {{ $store.state.loggedInUser.lastName  }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="$store.state.loggedInUser.region">
                      {{ $store.state.loggedInUser.region }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-for="(item, index) in menuItems"
                  :key="index"
                  :to="item.path"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="downloadExcel"
                >
                  <v-list-item-title>Deal Data Export</v-list-item-title>
                </v-list-item>
                <v-list-item @click="logOut"
                >
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </v-toolbar-items>
        <v-app-bar-nav-icon
          class="hidden-576-and-up"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>
        <template slot="extension" v-if="!hideExtensionBar">
          <div class="d-flex align-center">
            <template v-if="isUser">
              <v-btn
                class="mr-4 hidden-810-and-down"
                rounded
                color="primary"
                depressed
                :disabled="!hasEditRight"
                to="/user-management/create"
              >
                <v-icon left>mdi-plus</v-icon>
                Create new user
              </v-btn>
            </template>
            <template v-else-if="isArchive">
            </template>
            <template v-else-if="isUserArchive">
            </template>
            <template v-else>
              <v-btn
                class="mr-4 hidden-810-and-down"
                rounded
                color="primary"
                depressed
                :disabled="isReadOnly"
                to="/board/upload"
              >
                <v-icon left>mdi-plus</v-icon>
                Create new
              </v-btn>
              <user-filter
                class="hidden-576-and-down"
                :is-disabled="hasSearchTerm"
                v-on:userSelected="emitGetDealsByUser($event)"
                v-on:noneSelected="emitSetNoneSelected"
              ></user-filter>
            </template>
            <div class="hidden-576-and-up header-extension text-small">
              <div class="font-weight-bold">SHS Pricing Escalation
<!--                <v-chip
                  class="ml-3"
                  label
                  outlined
                  small
                  color="#02787d"
                  >Beta Version
                </v-chip>-->
              </div>
            </div>
          </div>
        </template>
      </template>
      <template v-if="hideExtensionBar && isExtraSmallScreenSize">
        <template slot="extension">
          <div class="d-flex align-center">
            <div class="hidden-576-and-up header-extension text-small">
              <div class="font-weight-bold">SHS Pricing Escalation
<!--                <v-chip
                  class="ml-3"
                  label
                  outlined
                  small
                  color="#02787d"
                  >Beta Version
                </v-chip>-->
              </div>
            </div>
          </div>
        </template>
      </template>
    </v-app-bar>
    <template v-if="fullHeader">
      <v-card class="mx-auto">
        <v-navigation-drawer
          v-model="drawer"
          app
          right
          temporary
          color="#f2f2f2"
          width="100vw"
        >
          <div class="menu-header--mobile white py-5 pl-4">
            <v-img
              src="@/assets/sh_logo_rgb.svg"
              width="125"
              height="30"
            ></v-img>
            <div class="align-top-right py-4 pr-4">
              <v-btn icon
                     @click="closeMenu"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
          <v-list nav>
            <v-list-item
              two-line
              v-if="$store.state.loggedInUser.firstName"
              :to="`/user-management/${$store.state.loggedInUser.id}`"
            >
              <v-list-item-avatar
                color="#e6e6e6"
                size="40"
              >
                {{ $store.getters.initials }}
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-smaller font-weight-bold">
                  {{ $store.state.loggedInUser.firstName }}
                  {{ $store.state.loggedInUser.lastName  }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="$store.state.loggedInUser.region">
                  {{ $store.state.loggedInUser.region }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <template v-for="(item, index) in menuItems"
            >
              <v-list-item
                :key="index"
                :to="item.path">
                {{ item.title }}
              </v-list-item>
            </template>
            <v-list-item @click="downloadExcel"
            >Deal Data Export
            </v-list-item>
            <v-list-item v-if="$store.state.loggedInUser.firstName" @click="logOut"
            >Logout
            </v-list-item>
            <v-divider></v-divider>
            <template v-if="!isUser && !isArchive && !isUserArchive">
              <v-list-item>
                <user-filter
                  class="mt-7"
                  :is-disabled="hasSearchTerm"
                  v-on:userSelected="emitGetDealsByUser($event)"
                  v-on:noneSelected="emitSetNoneSelected"
                ></user-filter>
              </v-list-item>
            </template>
            <v-list-item>
              <template v-if="!isUser && !isArchive && !isUserArchive">
                <v-text-field
                  v-model="searchTerm"
                  flat
                  outlined
                  rounded
                  solo
                  hide-details
                  dense
                  clearable
                  prepend-inner-icon="mdi-magnify"
                  label="Search in all Deals"
                  class="search-field mb-5"
                  v-on:keyup="emitSearchFilter"
                  v-on:focus.once="emitGetDealsComplete"
                ></v-text-field>
              </template>
              <template v-else-if="isArchive">
                <v-text-field
                  v-model="searchTerm"
                  flat
                  outlined
                  rounded
                  solo
                  hide-details
                  dense
                  clearable
                  prepend-inner-icon="mdi-magnify"
                  label="Search in Archive"
                  class="search-field mt-8 mb-5"
                  v-on:keyup.enter="emitSearchFilter"
                  v-on:click:clear="emitSearchCleared"
                ></v-text-field>
              </template>
              <template v-else-if="isUserArchive">
                <v-text-field
                  v-model="searchTerm"
                  flat
                  outlined
                  rounded
                  solo
                  hide-details
                  dense
                  clearable
                  append-icon="mdi-magnify"
                  label="Search in User Archive"
                  class="search-field hidden-576-and-down mr-4"
                  v-on:keyup="emitSearchFilter"
                ></v-text-field>
              </template>
              <template v-else>
                <v-text-field
                  v-model="searchTerm"
                  flat
                  outlined
                  rounded
                  solo
                  hide-details
                  dense
                  clearable
                  prepend-inner-icon="mdi-magnify"
                  label="Search User"
                  class="search-field mt-8 mb-5"
                  v-on:keyup="emitSearchFilter"
                ></v-text-field>
              </template>
              </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-card>
    </template>
  </div>
</template>

<script>
import ApiService from '@/services/ApiService';
import UserFilter from './UserFilter.vue';
import dealFunctions from '../mixins/DealFunctions';

export default {
  name: 'ShsHeader',
  components: {
    UserFilter,
  },
  mixins: [
    dealFunctions,
  ],
  props: {
    fullHeader: Boolean,
    isUser: Boolean,
    isArchive: Boolean,
    isUserArchive: Boolean,
  },
  data() {
    return {
      drawer: false,
      searchTerm: null,
      menuItems: [
        { title: 'Deal Board', path: '/board' },
        { title: 'Deal Archive', path: '/archive' },
        { title: 'User Management', path: '/user-management' },
        { title: 'User Archive', path: '/user-archive' },
      ],
    };
  },
  mounted() {
    if (typeof this.loggedInUser.firstName === 'undefined') {
      if (localStorage.getItem('currentUser')) {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        this.$store.commit('setLoggedInUser', user);
        this.$store.commit('setUserReadOnly', user.readOnly);
      } else if (this.fullHeader) {
        this.$store.dispatch('getLoggedInUser');
      }
    }
  },
  methods: {
    closeMenu() {
      this.drawer = false;
    },
    emitSearchFilter() {
      this.$emit('searchFilter', this.searchTerm);
    },
    emitGetDealsComplete() {
      this.$emit('getDealsComplete');
    },
    emitSetNoneSelected() {
      this.$emit('setNoneSelected');
    },
    emitSearchTermNull() {
      this.$emit('searchTermNull');
    },
    emitGetDealsByUser(user) {
      this.$emit('getDealsByUser', user);
    },
    emitSearchCleared() {
      this.$emit('searchCleared');
    },
    logOut() {
      this.$auth.signOut();
    },
    downloadExcel() {
      ApiService.downloadExcel();
    },
  },
  computed: {
    loggedInUser() {
      return this.$store.state.loggedInUser;
    },
    hasEditRight() {
      const userPerms = this.$store.state.loggedInUser.perms;
      return userPerms != null && userPerms.includes('USER_MANAGEMENT');
    },
    isReadOnly() {
      return this.$store.state.userReadOnly;
    },
    hideExtensionBar() {
      return !this.fullHeader;
    },
    setHeaderHeight() {
      let height = '112';
      if (this.isExtraSmallScreenSize) height = '70';
      return height;
    },
    hasSearchTerm() {
      if (this.searchTerm && this.searchTerm.length > 0) return true;
      return false;
    },
    isExtraSmallScreenSize() {
      return this.$vuetify.breakpoint.width <= this.$const.BREAKPOINT_XS_MWX; // TODO use $vuetify.breakpoint.xs for custom breakpoints
    },
  },
  watch: {
    searchTerm(val) {
      if (val == null) {
        this.emitSearchTermNull();
      } else if (val === '') this.emitGetDealsComplete();
    },
  },
};
</script>

<style lang="scss">
  @import 'src/scss/colors';
  .shs-app.theme--light {
    .v-navigation-drawer {
      .menu-header--mobile {
        height: 70px;
      }
    }
    .shs-header {
      &.border-bottom {
        border-bottom: 1px solid $gray-tint-4;
      }
      .app-title-headline {
        white-space: nowrap;
      }
      @media screen and (max-width: 810px) {
        .hidden-810-and-down {
          display: none;
        }
      }
      @media screen and (max-width: 760px) {
        .hidden-760-and-down {
          display: none;
        }
      }
      @media screen and (max-width: 759px) and (min-width: 577px) {
        .hidden-759-to-577 {
          display: none;
        }
      }
      @media screen and (max-width: 576px) {
        .hidden-576-and-down {
          display: none;
        }
      }
      @media screen and (min-width: 577px) {
        .hidden-576-and-up {
          display: none;
        }
      }
    }
    .v-menu__content {
      a.v-list-item:hover,
      .v-list-item:not(.v-list-item--two-line):hover {
        color: $orange !important;
        &::before {
          opacity: 0;
        }
      }
      .v-list-item--link:before {
        opacity: 0 !important;
      }
    }
  }
  .v-toolbar {
    &__extension {
      background-color: $gray-tint-6;
      .v-input__control {
        max-height: 36px;
      }
    }
  }
  .theme--light .v-navigation-drawer__content .v-list-item--active:hover::before,
  .theme--light .v-navigation-drawer__content .v-list-item--active::before {
    opacity: 0 !important;
  }
</style>
